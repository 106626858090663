import { IGameEvents, IGameOptions, IReelOptions, ISymbolOptions } from './contracts/ICommon';

export const enum WINNINGS {
    FREESPINS = 'freespins',
    NORMAL = 'normal',
    SCATTER = 'scatter'
}

export enum SCALE_METHODS {
    CUSTOM = 'CUSTOM'
};

export enum DEVICE_MODELS {
    IPHONE = 'IPHONE',
};

export enum DEVICE_ENVIORMENT {
    MOBILE,
    DESKTOP,
    TABLET
};

export enum DEVICE_OS {
    ANDROID,
    IPHONE
};

export enum GAME_MONEY_MODES {
    FUN = 'fun',
    REAL = 'real'
};

export enum WIN_MODES {
    BIG_WIN = 'big',
    SUPER_WIN = 'super',
    MEGA_WIN = 'mega',
    TOTAL_WIN = 'total'
};

export const WIN_CONFIG = {
    bigThresholdCoeff: 20,
    superThresholdCoeff: 50,
    megaThresHoldCoeff: 100,
    coinThrowThresholdCoeff: 4,

};
export const FAKE_NO_WIN_RESPONSE = {"stats":{"b":0,"fm":100000,"currency":"EUR","tokens":0,"realityCheck":{"result":800,"sessionMinutes":0,"lossLimit":null,"winSum":0,"resetedResult":0,"hardErrors":false,"totalSessionMinutes":0,"sessionLimitSeconds":null,"betSum":300,"status":"ok"}},"grid":"BASE_REELS","ended":true,"stops":[57,48,30,64,57],"roundType":"normal","winnings":[],"roundId":47890043,"win":0,"symbols":[["K","T","D"],["Q","Q","C"],["B","C","C"],["D","D","A"],["T","Q","B"]],"ba":100};
export const DEFAULT_GRID = 'BASE_REELS';
export const GAME_OPTIONS: IGameOptions = {
    width: 750,
    height: 1333,
    backgroundColor: 0x1e2035
};

export const REEL_OPTIONS: IReelOptions = {
    reels: 5,
    symbolsPerReel: 5,
    width: 135,
    height: 150,
    playAnimationDuration: 2,
    delayBetweenReelsPlay: 0.1,
    delayBetweenReelsStop: 0.3,
    distancePerFrame: 50,
    turboPlayAnimationDuration: 0.2,
    turboDelayBetweenReelsPlay: 0,
    turboDelayBetweenReelsStop: 0.001,
    turboDistancePerFrame: 55,
    anticipationDistancePerFrame: 55,
    spins: 3,
    spinsTurbo: 2,
    spinsAnticipation: 5

};
export const SYMBOL_OPTIONS: ISymbolOptions = {
    width: 150,
    height: 150
};
export const BLUR_SYMBOLS_SUFFIX = "_blur";
export const SPECIAL_SYMBOL_NOT_TO_BLUR = "CS";

export const GAME_EVENTS: IGameEvents = {
    AUTHENTICATED: 'AUTHENTICATED',
    STOP_MOVING: 'STOP_MOVING',
    RESIZE_WINDOW: 'RESIZE_WINDOW',
    TEST_EVENTS: 'TEST_EVENTS',
    TEST_EVENTS_2: 'TEST_EVENTS_2',
    GAME_CREATED: 'GAME_CREATED',
    LOAD_RESOURCES: 'LOAD_RESOURCES',
    RESOURCES_READY: 'RESOURCES_READY',
    LOAD_PROGRESS_RESOURCES: 'LOAD_PROGRESS_RESOURCES',
    REELS_START: 'REELS_START',
    REEL_STOP: 'REEL_STOP',
    REELS_STOP: 'REELS_STOP',
    FAKE_STOP_REELS_ERROR: 'FAKE_STOP_REELS_ERROR',
    AUTOPLAY_SPIN: 'AUTOPLAY_SPIN',
    AUTOPLAY_STOP: 'AUTOPLAY_STOP',
    BALANCE_UPDATED: 'BALANCE_UPDATED',
    STOP_SYMBOL: 'STOP_SYMBOL',
    PAUSE_GAME: 'PAUSE_GAME',
    RESUME_GAME: 'RESUME_GAME',
    ROUND_FINISH: 'ROUND_FINISH',
    PROMOTIONAL_FREE_SPINS_ENDED: 'PROMOTIONAL_FREE_SPINS_ENDED',
    SHOW_WINNING_SYMBOLS: 'SHOW_WINNING_SYMBOLS',
    SHOW_WIN_SCREEN: 'SHOW_WIN_SCREEN',
    TOTAL_WIN_FINISHED: 'TOTAL_WIN_FINISHED',
    FORCE_STOP_BANGUP: 'FORCE_STOP_BANGUP',
    FS_REEL_STOP: 'FS_REEL_STOP',
    ENABLE_SPIN_BUTTON: 'ENABLE_SPIN_BUTTON',
    ON_ANTICIPATION_ACTIVE: 'ON_ANTICIPATION_ACTIVE',
    
    SHOW_FS_PANEL: 'SHOW_FS_PANEL',
    HIDE_FS_PANEL: 'HIDE_FS_PANEL',
    FS_PANEL_UPDATE_TOTAL_SPINS: 'FS_PANEL_UPDATE_TOTAL_SPINS',
    FS_PANEL_UPDATE_CURRENT_SPIN: 'FS_PANEL_UPDATE_CURRENT_SPIN',
    FS_PANEL_UPDATE_TOTAL_WIN: 'FS_PANEL_UPDATE_TOTAL_WIN',
    FS_BANGUP_COMPLETED: 'FS_BANGUP_COMPLETED',

    SHOW_UI_FOOTER: 'SHOW_UI_FOOTER',

    SKIP_COIN_ANIMATION: 'SKIP_COIN_ANIMATION',
    SHOW_EXPANDING_SYMBOLS: 'SHOW_EXPANDING_SYMBOLS',

    NETWORK_ERROR: 'NETWORK_ERROR',
    FORCE_SET_SOUNDS: 'FORCE_SET_SOUNDS',
    FORCE_SET_TURBO: 'FORCE_SET_TURBO',
    TOGGLE_PAYTABLE: 'TOGGLE_PAYTABLE', // requested from FEIM
    TOGGLE_HELP: 'TOGGLE_HELP', // requested from FEIM
    ERROR_REQUEST: 'ERROR_REQUEST',
    GAME_INITIALIZED: 'GAME_INITIALIZED',

    FEIM_STOP_AUTOPLAY: 'FEIM_STOP_AUTOPLAY',
};

export const REEL_SYMBOLS: string[] = [
    'T',
    'J',
    'Q',
    'K',
    'A',
    'D',
    'C',
    'B'
];

export const REEL_SYMBOL_RESOURCES_NAMES: string[] = [
    ...REEL_SYMBOLS,
    'CS',
    'T_blur',
    'J_blur',
    'Q_blur',
    'K_blur',
    'A_blur',
    'D_blur',
    'C_blur',
    'B_blur'
];

export const REEL_SYMBOL_RESOURCES_NAMES_BACKGROUND: string[] = [
    'ROYALS_BACKGROUND',
    'D_BACKGROUND',
    'C_BACKGROUND',
    'B_BACKGROUND'
];

export const GAME_RESOURCES: any = {
    // SYMBOLS
    'T' : './assets/{{lang}}/symbols/royal_10.png',
    'J' : './assets/{{lang}}/symbols/royal_j.png',
    'Q' : './assets/{{lang}}/symbols/royal_q.png',
    'K' : './assets/{{lang}}/symbols/royal_k.png',
    'A' : './assets/{{lang}}/symbols/royal_a.png',
    'D' : './assets/{{lang}}/symbols/stone_green.png',
    'C' : './assets/{{lang}}/symbols/stone_red.png',
    'B' : './assets/{{lang}}/symbols/stone_white.png',
    'CS' : './assets/{{lang}}/symbols/coin.png',
    'WILD' : './assets/{{lang}}/symbols/coinWild.png',
    'FS': './assets/{{lang}}/symbols/coinFS.png',
    // SYMBOLS BLUR
    'T_blur' : './assets/{{lang}}/symbols/blur/royal-10_blur.png',
    'J_blur' : './assets/{{lang}}/symbols/blur/royal-j_blur.png',
    'Q_blur' : './assets/{{lang}}/symbols/blur/royal-q_blur.png',
    'K_blur' : './assets/{{lang}}/symbols/blur/royal-k_blur.png',
    'A_blur' : './assets/{{lang}}/symbols/blur/royal-a_blur.png',
    'D_blur' : './assets/{{lang}}/symbols/blur/green-stone_blur.png',
    'C_blur' : './assets/{{lang}}/symbols/blur/red-stone_blur.png',
    'B_blur' : './assets/{{lang}}/symbols/blur/diamond-stone_blur.png',

    // SYMBOLS mask
    'D_mask': './assets/{{lang}}/symbols/masks/green-stone_mask.png',
    'C_mask': './assets/{{lang}}/symbols/masks/red-stone_mask.png',
    'B_mask': './assets/{{lang}}/symbols/masks/yellow-stone_mask.png',

    // SYMBOLS BACKGROUND
    'ROYALS_BACKGROUND' : './assets/{{lang}}/symbols/background/royals_background.png',
    'D_BACKGROUND' : './assets/{{lang}}/symbols/background/stone_green_background.png',
    'C_BACKGROUND' : './assets/{{lang}}/symbols/background/stone_red_background.png',
    'B_BACKGROUND' : './assets/{{lang}}/symbols/background/stone_white_background.png',

    // SYMBOLS SWIPE
    'SYMBOLS_SWIPE': './assets/{{lang}}/symbols/symbol_swipe.png',

    // COIN MASKS
    'FORTUNE_MASK': './assets/{{lang}}/symbols/masks/coin_fortune_mask.png',
    'PLENTY_MASK': './assets/{{lang}}/symbols/masks/coin_plenty_mask.png',
    'SPINS_MASK': './assets/{{lang}}/symbols/masks/coin_spins_mask.png',
    'WILD_MASK': './assets/{{lang}}/symbols/masks/coin_wild_mask.png',

    'BLICK': './assets/{{lang}}/blick/blick.json',

    // COMMON
    'UIUpperPanel': './assets/{{lang}}/common/ui_bar.png',
    'UIBackground': './assets/{{lang}}/common/background.jpg',
    'Logo': './assets/{{lang}}/common/logo.png',
    'IOSGifAnimation': './assets/{{lang}}/common/gif_02.gif',

    // CLOSE BUTTON
    'ButtonFrame2': './assets/{{lang}}/loading/button_frame2.png',
    'ButtonNormal2': './assets/{{lang}}/loading/button_normal2.png',
    'ButtonHover2': './assets/{{lang}}/loading/button_hover2.png',

    // SLOT
    'UISlot': './assets/{{lang}}/slot/slot_normal.png',
    'UISlotPlants': './assets/{{lang}}/slot/00_shadow2.png',
    'UINormalReels': './assets/{{lang}}/slot/normal_reels.jpg',
    'UISlotFS': './assets/{{lang}}/slot/slot_freespins.png',
    'UINormalReelsFS': './assets/{{lang}}/slot/freespins_reels.jpg',
    'UISlotMask' : './assets/{{lang}}/slot/slot_mask.png',
    'UISlotMaskWide': './assets/{{lang}}/slot/slot_mask_wide.png',

    // EXPANDING SYMBOLS
    'FSExpandingSymbolFire': './assets/{{lang}}/expanding_fire/expanding_fire.json',

    // Free spins popup
    'FSPopupWrapper': './assets/{{lang}}/fs_popup/fs_popup_base.png',
    'FSPopupMask': './assets/{{lang}}/fs_popup/fs_splash-mask.png',
    // 'FSPopupText': './assets/{{lang}}/fs_popup/texts/fs_popup_sop.png',
    'FSPopupSpecialSymbolText': './assets/{{lang}}/fs_popup/texts/fs_popup_expand.png',
    'FSPlayButton': './assets/{{lang}}/fs_popup/texts/fs_popup_play_inactive.png',
    'FSPlayButtonHover': './assets/{{lang}}/fs_popup/texts/fs_popup_play_normal.png',
    // 'FSFreeSpinsText': './assets/{{lang}}/fs_popup/texts/fs_popup_freespins.png',
    'FSPopupGold': './assets/{{lang}}/fs_popup/gold.png',
    'FSPopupPlayNormal': './assets/{{lang}}/fs_popup/fs_pop_button_normal.png',
    'FSPopupPlayHover': './assets/{{lang}}/fs_popup/fs_pop_button_hover.png',

    //Free spins panel
    'FSPanelBackground': './assets/{{lang}}/fs_panel/aztec_freespins-panel.png',
    'FSPanelCoins': './assets/{{lang}}/fs_panel/aztec_freespins-panel_coins.png',
    'FSPanelFSText': './assets/{{lang}}/fs_panel/texts/fs_panel_freespins.png',
    // 'FSPanelOfText': './assets/{{lang}}/fs_panel/texts/of_text.png',
    // Anticipation
    'AnticipationBase': './assets/{{lang}}/anticipation/01_base.png',
    'AnticipationBorders': './assets/{{lang}}/anticipation/02_borders.png',
    'AnticipationBordersAnimation': './assets/{{lang}}/anticipation/03_border-animation.png',
    'AnticipationDot': './assets/{{lang}}/anticipation/animation_dot.png',
    'AnticipationStar': './assets/{{lang}}/anticipation/animation_star.png',
    'AnticipationLine': './assets/{{lang}}/anticipation/animation_line.png',

    // SPIN BUTTON RESOURCES
    'UISpinFrame': './assets/{{lang}}/spin/ui_spin_frame.png',
    // 'UISpinButton': './assets/{{lang}}/spin/spin_button.png',
    'UISpinActive': './assets/{{lang}}/spin/ui_spin_active.png',
    // 'UISpinIcon': './assets/{{lang}}/spin/ui_spin_icon.png',
    'UISpinNormal': './assets/{{lang}}/spin/ui_spin_normal.png',
    'UISpinPush': './assets/{{lang}}/spin/ui_spin_push.png',
    // 'UISpinStop': './assets/{{lang}}/spin/ui_spin_stop.png',
    // 'UISpinInactive': './assets/{{lang}}/spin/ui_spin_inactive.png',

    // BUTTONS
    // 'UIAutospinActive': './assets/{{lang}}/buttons/ui_autospin_active.png',
    // 'UIAutospinNormal': './assets/{{lang}}/buttons/ui_autospin_normal.png',
    // 'UIAutospinPush': './assets/{{lang}}/buttons/ui_autospin_push.png',

    // 'UITurboNormal': './assets/{{lang}}/buttons/ui_turbo_normal.png',
    // 'UITurboActive': './assets/{{lang}}/buttons/ui_turbo_active.png',
    // 'UITurboPush': './assets/{{lang}}/buttons/ui_turbo_push.png',

    // 'UIBetNormal': './assets/{{lang}}/buttons/ui_bet_normal.png',

    // ICONS
    'IconMinus': './assets/{{lang}}/icons/minus.png',
    'IconPlus': './assets/{{lang}}/icons/plus.png',
    'IconAuto': './assets/{{lang}}/icons/ui_auto_icon.svg',
    'IconAutoStop': './assets/{{lang}}/icons/ui_auto_icon_stop.svg',
    'IconTurbo': './assets/{{lang}}/icons/ui_turbo_icon.svg',
    'IconHistory': './assets/{{lang}}/icons/ui_history_icon.svg',
    'IconPaytable': './assets/{{lang}}/icons/ui_top-pays.svg',
    'IconStroke': './assets/{{lang}}/icons/stroke.png',
    'IconHome': './assets/{{lang}}/icons/ui_top-home.svg',

    // ADV
    'ADV1': './assets/{{lang}}/adv/texts/adv_01.png',
    'ADV2': './assets/{{lang}}/adv/texts/adv_02.png',
    'ADV3': './assets/{{lang}}/adv/texts/adv_03.png',
    'ADV4': './assets/{{lang}}/adv/texts/adv_04.png',

    // FIRE
    'FireJSON': './assets/{{lang}}/fire/fire.json',
    'FireGlow': './assets/{{lang}}/fire/fire_glow.png',

    // Symbols jsons
    'B_JSON': './assets/{{lang}}/diamond/diamond.json',

    // coin
    'CoinWild': './assets/{{lang}}/coin_rotation_wild/coin_rotation_wild.json',
    'CoinFS': './assets/{{lang}}/coin_rotation_fs/coin_rotation_fs.json',

    //coin wild text
    'coinWildTxt': './assets/{{lang}}/coin_wild_text/wild_text.png',
    'coinFortuneTxt': './assets/{{lang}}/coin_wild_text/fortune_text.png',
    'coinWildBase': './assets/{{lang}}/coin_wild_text/wild_text_base.png',
    'coinWildGlow': './assets/{{lang}}/coin_wild_text/wild_glow.png',

    //coin fs text
    'coinFSBase': './assets/{{lang}}/coin_fs_text/fs_base.png',
    'coinFSGlow': './assets/{{lang}}/coin_fs_text/fs_glow.png',
    'coinPlentyTxt': './assets/{{lang}}/coin_fs_text/plenty_text.png',
    'coinOfTxt': './assets/{{lang}}/coin_fs_text/of_text.png',
    'coinSpinsTxt': './assets/{{lang}}/coin_fs_text/spins_text.png',


    // WINS

    'big': './assets/{{lang}}/win/texts/win_big.png',
    'mega': './assets/{{lang}}/win/texts/win_mega.png',
    'super': './assets/{{lang}}/win/texts/win_super.png',
    'total': './assets/{{lang}}/win/texts/win_total.png',
    'winGlow': './assets/{{lang}}/win/glow_new.png',
    'win': './assets/{{lang}}/win/texts/win.png',
    // 'winSparkle': './assets/{{lang}}/win/text_blick.png',

    // PAYTABLE

    // 'paytableBackgr': './assets/{{lang}}/paytable/aztec_paytable_background.jpg',
    'paytableOrnaments': './assets/{{lang}}/paytable/ornaments.png',
    'paytableLogo': './assets/{{lang}}/paytable/aztec_paytable_logo.png',
    'paytablePaylines': './assets/{{lang}}/paytable/aztec_paytable_paylines.png',
    'paytableScreen': './assets/{{lang}}/paytable/aztec_paytable_screen01.png',
    'paytableScreen2': './assets/{{lang}}/paytable/aztec_paytable_screen02.png',
    'paytableFSBadge': './assets/{{lang}}/paytable/aztec_paytable_spinsofplenty_badge.png',
    'paytableWild': './assets/{{lang}}/paytable/aztec_paytable_wild.png',
    'paytableFS': './assets/{{lang}}/paytable/aztec_paytable_fs_icon.png',
    'paytablePanel': './assets/{{lang}}/paytable/aztec_paytable_fs_panel.png',
    'paytableBtnActive': './assets/{{lang}}/paytable/close_button_active.png',
    'paytableBtnNormal': './assets/{{lang}}/paytable/close_button_normal.png',

    //Paytable texts
    'paytableTextHeader1': './assets/{{lang}}/paytable/texts/game_rules_paytable.png',
    'paytableTextHeader2': './assets/{{lang}}/paytable/texts/game_rules_features.png',
    'paytableTextHeader3': './assets/{{lang}}/paytable/texts/game_rules_symbols.png',
    'paytableTextHeader4': './assets/{{lang}}/paytable/texts/game_rules_paylines.png',
    'paytableText1': './assets/{{lang}}/paytable/texts/game_rules_rule1.png',
    'paytableText2': './assets/{{lang}}/paytable/texts/game_rules_rule2.png',
    'paytableText3': './assets/{{lang}}/paytable/texts/game_rules_rule3.png',
    'paytableText4': './assets/{{lang}}/paytable/texts/game_rules_rule4.png',
    'paytableText7': './assets/{{lang}}/paytable/texts/game_rules_rule7.png',
    'paytableText8': './assets/{{lang}}/paytable/texts/game_rules_rule8.png',
    'paytableText9': './assets/{{lang}}/paytable/texts/game_rules_rule9.png',
    'paytableText13': './assets/{{lang}}/paytable/texts/game_rules_rule13.png',
    'paytableText14': './assets/{{lang}}/paytable/texts/game_rules_rule14.png',
    'paytableText15': './assets/{{lang}}/paytable/texts/game_rules_rule15.png',
    'paytableText16': './assets/{{lang}}/paytable/texts/game_rules_rule16.png',
    'paytableText17': './assets/{{lang}}/paytable/texts/game_rules_rule17.png',
    'paytableText18': './assets/{{lang}}/paytable/texts/game_rules_rule18.png',
    'paytableText19': './assets/{{lang}}/paytable/texts/game_rules_rule19.png',
    'paytableText20': './assets/{{lang}}/paytable/texts/game_rules_rule20.png',

    //PAYTABLE SYMBOL WINS (NEXT TO THE SYMBOLS)
    // 'symbolWin10': './assets/{{lang}}/paytable/symbolWins/10_wins.png',
    // 'symbolWinA': './assets/{{lang}}/paytable/symbolWins/a_wins.png',
    // 'symbolWinDiamond': './assets/{{lang}}/paytable/symbolWins/diamond_wins.png',
    // 'symbolWinGreen': './assets/{{lang}}/paytable/symbolWins/green_wins.png',
    // 'symbolWinJ': './assets/{{lang}}/paytable/symbolWins/j_wins.png',
    // 'symbolWinK': './assets/{{lang}}/paytable/symbolWins/k_wins.png',
    // 'symbolWinQ': './assets/{{lang}}/paytable/symbolWins/q_wins.png',
    // 'symbolWinRed': './assets/{{lang}}/paytable/symbolWins/red_wins.png',
    // 'symbolWinScatter': './assets/{{lang}}/paytable/symbolWins/scatter_wins.png',

    // HELP
    'rulesText1': './assets/{{lang}}/paytable/texts/rules_howtoplay_1.png',
    'rulesText2': './assets/{{lang}}/paytable/texts/rules_howtoplay_2.png',
    'rulesText3': './assets/{{lang}}/paytable/texts/rules_howtoplay_3.png',
    'rulesText4': './assets/{{lang}}/paytable/texts/rules_howtoplay_4.png',
    'rulesText5': './assets/{{lang}}/paytable/texts/rules_howtoplay_5.png',
    'rulesText6': './assets/{{lang}}/paytable/texts/rules_howtoplay_6.png',
    'rulesTitle': './assets/{{lang}}/paytable/texts/rules_howtoplay_title.png',


    //Errors
    'error_400': './assets/{{lang}}/errors/texts/error_400.png',
    'error_404': './assets/{{lang}}/errors/texts/error_404.png',
    'error_503': './assets/{{lang}}/errors/texts/error_503.png',
    'error_506': './assets/{{lang}}/errors/texts/error_506.png',
    'error_517': './assets/{{lang}}/errors/texts/error_517.png',
    'error_520': './assets/{{lang}}/errors/texts/error_520.png',
    'error_521': './assets/{{lang}}/errors/texts/error_521.png',
    'error_522': './assets/{{lang}}/errors/texts/error_522.png',
    'error_523': './assets/{{lang}}/errors/texts/error_523.png',
    'error_524': './assets/{{lang}}/errors/texts/error_524.png',
    'error_531': './assets/{{lang}}/errors/texts/error_531.png',
    'error_533': './assets/{{lang}}/errors/texts/error_533.png',
    'error_534': './assets/{{lang}}/errors/texts/error_534.png',
    'error_544': './assets/{{lang}}/errors/texts/error_544.png',
    'error_545': './assets/{{lang}}/errors/texts/error_545.png',
    'error_546': './assets/{{lang}}/errors/texts/error_546.png',
    'error_554': './assets/{{lang}}/errors/texts/error_554.png',
    'error_555': './assets/{{lang}}/errors/texts/error_555.png',
    'error_556': './assets/{{lang}}/errors/texts/error_556.png',
    'error_600': './assets/{{lang}}/errors/texts/error_600.png',
    'error_601': './assets/{{lang}}/errors/texts/error_601.png',
    'error_connection': './assets/{{lang}}/errors/texts/error_connectionError.png',
    'error_loginticket': './assets/{{lang}}/errors/texts/error_noLoginTicket.png',
    'error_noticket': './assets/{{lang}}/errors/texts/error_noTicketError.png',

    // Reality check
    'realityCheck_cancel' : './assets/{{lang}}/realitycheck/texts/realityCheck_cancel.png',
    'realityCheck_confirm' : './assets/{{lang}}/realitycheck/texts/realityCheck_confirm.png', 
    'realityCheck_history' : './assets/{{lang}}/realitycheck/texts/realityCheck_history.png', 
    // 'realityCheck_message' : './assets/{{lang}}/realitycheck/texts/realityCheck_message.png', 
    // 'realityCheck_ok' : './assets/{{lang}}/realitycheck/texts/realityCheck_ok.png', 
    // 'realityCheck_warning' : './assets/{{lang}}/realitycheck/texts/realityCheck_warning.png',
    'realityCheckURL1' : './assets/{{lang}}/realitycheck/texts/realityCheck_message_1.png',
    'realityCheckURL3' : './assets/{{lang}}/realitycheck/texts/realityCheck_message_3.png',
    'realityCheckURL4' : './assets/{{lang}}/realitycheck/texts/realityCheck_message_4.png',


    // auto play
    'autoplay_header': './assets/{{lang}}/settings/texts/settings_autoplay_head.png',
    'autoplay_lossLimit': './assets/{{lang}}/settings/texts/settings_autoplay_lossLimit.png',
    'autoplay_singlewinlimit': `./assets/{{lang}}/settings/texts/settings_autoplay_singleWinLimit.png`,
    'autoplay_singlewinlimitEnd': `./assets/{{lang}}/settings/texts/settings_autoplay_end_singleWinLimit.png`,
    'autoplay_start': './assets/{{lang}}/settings/texts/settings_autoplay_start.png',
    'autoplay_total': './assets/{{lang}}/settings/texts/settings_autoplay_totalspins.png',

    'autoplay_end': './assets/{{lang}}/settings/texts/settings_autoplay_end.png',
    'autoplay_lossLimit_reach': './assets/{{lang}}/settings/texts/settings_autoplay_end_lossLimit.png',
    'autoplay_unfinished': './assets/{{lang}}/settings/texts/settings_unfinished.png',
    'autoplay_unfinished_nowin': './assets/{{lang}}/settings/texts/settings_unfinished_nowin.png',
    'autoplay_unfinished_win': './assets/{{lang}}/settings/texts/settings_unfinished_win.png',

    
    // promotional freespins
    'fsCongrats': './assets/{{lang}}/freerounds/texts/freerounds_introText.png',
    'fsEnd2': './assets/{{lang}}/freerounds/texts/freerounds_end2.png',
    'fsOptions': './assets/{{lang}}/freerounds/texts/freerounds_introText2.png',
    'fsUnfinished': './assets/{{lang}}/freerounds/texts/freerounds_unfinished.png',

    'FSBet': './assets/{{lang}}/freerounds/texts/freerounds_bet.png',
    'FSLabel': './assets/{{lang}}/freerounds/texts/freerounds_label.png',
    'FSTotalWin': './assets/{{lang}}/freerounds/texts/freerounds_totalWin.png',

    // fs splash screen
    'fsSplashCongrats': './assets/{{lang}}/freerounds/texts/freerounds_splash_1.png',
    'fsSplashTexts': './assets/{{lang}}/freerounds/texts/freerounds_splash_2.png',

    // UI
    'uiSkip': './assets/{{lang}}/ui/texts/ui_skip.png',
    'uiLater': './assets/{{lang}}/ui/texts/ui_play_later.png',
    'uiDelete': './assets/{{lang}}/ui/texts/ui_no_thanks.png',
    'uiContinue': './assets/{{lang}}/ui/texts/ui_continue.png',
    'uiPlay': './assets/{{lang}}/ui/texts/ui_play.png',
    'uiPlayHover': './assets/{{lang}}/ui/texts/ui_play_hover.png',
    'uiPlayLoading': './assets/{{lang}}/ui/texts/ui_loading.png',
    'uiBet': './assets/{{lang}}/ui/texts/gameControl_bet.png',
    'uiBalance': './assets/{{lang}}/ui/texts/gameControl_balance.png',
    'uiBonusEnded': './assets/{{lang}}/ui/texts/bonus_ended.png',


    // coin fountain
    'coinFountain': './assets/{{lang}}/coin_fountain/coin_fountain.json',
    // BITMAP FONTS
    'bangup' : './assets/{{lang}}/bitmap-fonts/bangup.xml',
    'fspanelFont' : './assets/{{lang}}/bitmap-fonts/freespinscount.xml',

    // SPINE ANIMATIONS
    // 'charactersJSON' : './assets/spine/powerup/export2/powerup.json',
    'jungleJSON' : './assets/spine/jungle/skeleton.json',
    'jungle2JSON' : './assets/spine/jungle_fs/skeleton_2.json',
    'fallingJSON' : './assets/spine/falling_leaves/falling.json',
    'introJSON' : './assets/spine/intro/intro_leaves.json',




    // SOUNDS
    'Animation_FiresLightUp': './assets/sounds/Animation_FiresLightUp.{{format}}',
    'BangupBIGLoop': './assets/sounds/BangupBIGLoop.{{format}}',
    'BangupInFreeSpinsLoop': './assets/sounds/BangupInFreeSpinsLoop.{{format}}',
    'BangupSmallLoop': './assets/sounds/BangupSmallLoop.{{format}}',
    'ClickSpin': './assets/sounds/ClickSpin.{{format}}',
    'CoinsAnimationLoop': './assets/sounds/CoinsAnimationV3Loop.{{format}}',
    // 'ExplosiveFXSplash': './assets/sounds/ExplosiveFXSplash.{{format}}',
    'FreeSpinsEndMusicStopper': './assets/sounds/FreeSpinsEndMusicStopper.{{format}}',
    'FreeSpinsLoop': './assets/sounds/FreeSpinsLoop.{{format}}',
    'FSNumberUp_1': './assets/sounds/FSNumberUp_1.{{format}}',
    'FSNumberUp_2': './assets/sounds/FSNumberUp_2.{{format}}',
    'FSNumberUp_3': './assets/sounds/FSNumberUp_3.{{format}}',
    'FSNumberUp_4': './assets/sounds/FSNumberUp_4.{{format}}',
    'FSNumberUp_5': './assets/sounds/FSNumberUp_5.{{format}}',
    'FSNumberUp_6': './assets/sounds/FSNumberUp_6.{{format}}',
    'FSNumberUp_7andAbove': './assets/sounds/FSNumberUp_7andAbove.{{format}}',
    'ReelFallSngle': './assets/sounds/ReelFallSngle.{{format}}',
    'SceneChangeFX': './assets/sounds/SceneChangeFX.{{format}}',
    'ShowFSSplash': './assets/sounds/ShowFSSplash.{{format}}',
    // 'SmallWildReveal': './assets/sounds/SmallWildReveal.{{format}}',
    'Spin1_Loop': './assets/sounds/Spin1_Loop.{{format}}',
    'Spin2_Loop': './assets/sounds/Spin2_Loop.{{format}}',
    'Spin3_Loop': './assets/sounds/Spin3_Loop.{{format}}',
    'Spin4_Loop': './assets/sounds/Spin4_Loop.{{format}}',
    'SplashMusicBeforeFSStarts_LOOP': './assets/sounds/SplashMusicBeforeFSStarts_LOOP.{{format}}',
    'UI_Click_1': './assets/sounds/UI_Click_1.{{format}}',
    'UI_Click_2': './assets/sounds/UI_Click_2.{{format}}',
    // 'UI_Click_3': './assets/sounds/UI_Click_3.{{format}}',
    // 'WildsSpinToReveal': './assets/sounds/WildsSpinToReveal.{{format}}',
    'WildSymbolsTurn': './assets/sounds/WildSymbolsTurn.{{format}}',
    'WildSymbolsTurnLONG': './assets/sounds/WildSymbolsTurnLONG.{{format}}',
    'WinBigBangupEnd1': './assets/sounds/WinBigBangupEnd1.{{format}}',
    'WinBigBangupEnd2': './assets/sounds/WinBigBangupEnd2.{{format}}',
    'WinBigBangupEnd3': './assets/sounds/WinBigBangupEnd3.{{format}}',
    'WinFreeSpinsGame': './assets/sounds/WinFreeSpinsGame.{{format}}',
    'WinSmallBangupEnd1': './assets/sounds/WinSmallBangupEnd1.{{format}}',
    'WinSmallBangupEnd2': './assets/sounds/WinSmallBangupEnd2.{{format}}',
    'WinSmallBangupEnd3': './assets/sounds/WinSmallBangupEnd3.{{format}}',
    'WinSmallBangupEnd4': './assets/sounds/WinSmallBangupEnd4.{{format}}',
    'SpinMusicLoop': './assets/sounds/SpinMusicV3Loop.{{format}}',
    
    'BackgroundConstantLoop': './assets/sounds/BackgroundConstantLoop.{{format}}',
    'ReelAnticipationEnd': './assets/sounds/ReelAnticipationEnd.{{format}}',
    'ReelAnticipationStart': './assets/sounds/ReelAnticipationLongerStart.{{format}}',
    'GameIntroStart': './assets/sounds/GameIntroOnStart.{{format}}',
    'SpinStart1': './assets/sounds/SpinStart1.{{format}}',
    'SpinStart2': './assets/sounds/SpinStart2.{{format}}',
    'SpinStart3': './assets/sounds/SpinStart3.{{format}}',
    'SpinStart4': './assets/sounds/SpinStart4.{{format}}',
    'CoinFall_1': './assets/sounds/CoinFall_1.{{format}}',
    'CoinFall_2': './assets/sounds/CoinFall_2.{{format}}',
    'CoinFall_3': './assets/sounds/CoinFall_3.{{format}}',
    'CoinFall_4': './assets/sounds/CoinFall_4.{{format}}',
    'FireLightUp1': './assets/sounds/FireLightUp1.{{format}}',
    'CoinSwordSoundSpin': './assets/sounds/CoinSwordSoundSpin.{{format}}',
    'Line1': './assets/sounds/Line1.{{format}}',
    'Line2': './assets/sounds/Line2.{{format}}',
    'Line3': './assets/sounds/Line3.{{format}}',
    'Line4': './assets/sounds/Line4.{{format}}',
    'Line5': './assets/sounds/Line5.{{format}}',
    'Line6': './assets/sounds/Line6.{{format}}',
    'Line7': './assets/sounds/Line7.{{format}}',
    'Line8': './assets/sounds/Line8.{{format}}',
    'Line9': './assets/sounds/Line9.{{format}}',
    'Line10': './assets/sounds/Line10.{{format}}'
};

///////////////////////////////////////////////////////////////////// BACKED COFNIG ////////////////////////////////////////////////////////
export const GAME_CONFIG = {
    gameConfigAPIEnpointURL: 'https://dev-casino-client.api.relaxg.net/capi/1.0/casino/games/getclientconfig',
    minSpinTime: 3000,
    immediatePayouts: false,
    RTP: 95,
    GAME_NAME: 'aztecluck',
    GAME_CLIENT_ID: 'html5',
    VERSION_STRING: 'game:0.0.54',

    GAME_DEFAULT_CURRENCY: 'EUR',
    GAME_DEFAULT_LANG: 'en_US',
    JURISDICTION: 'MT',

    GAME_DEFAULT_PARTNER_ID: 1,
    GAME_DEFAULT_CLIENT_ID: '',
    GAME_DEFAULT_TICKET: '',

    GAME_DEFAULT_MONEYMODE: GAME_MONEY_MODES.FUN,
    FULL_SCREEN: 'true',
    HIDE_HOME: 'false',
    DEFAULT_RC_SHOW_RESULTS: 'false',
    GAME_CHANNEL: 'web',
    IS_IFRAME: false
};
