
import { Component, Mixins, Prop } from 'vue-property-decorator';
import GlobalMixin from '../../mixins/GlobalMixin.vue';
import { EventBus } from '../../utils/EventBus';
import HelpImage from './HelpImage.vue';

@Component({
    components: {
        HelpImage
    }
})
export default class Help extends Mixins(GlobalMixin) {
    lastUpdateDate = new Date(2025, 0, 20).toLocaleDateString();
    @Prop({ default: false })
    isActive?: boolean;
    closeButton = `./assets/${window.lang}/paytable/close_button_normal.png`;
    closeButtonHover = `./assets/${window.lang}/paytable/close_button_active.png`;
    paytableOrnaments = `./assets/${window.lang}/paytable/ornaments.png`;
    paytableLogo = `./assets/${window.lang}/paytable/aztec_paytable_logo.png`;
    paytableTextHeader1 = `./assets/${window.lang}/paytable/texts/rules_howtoplay_title.png`;
    paytableTextHeader = `./assets/${window.lang}/paytable/texts/game_rules_header.png`;
    rulesText1 = `./assets/${window.lang}/paytable/texts/rules_howtoplay_1.png`;
    rulesText2 = `./assets/${window.lang}/paytable/texts/rules_howtoplay_2.png`;
    rulesText3 = `./assets/${window.lang}/paytable/texts/rules_howtoplay_3.png`;
    rulesText4 = `./assets/${window.lang}/paytable/texts/rules_howtoplay_4.png`;
    rulesText5 = `./assets/${window.lang}/paytable/texts/rules_howtoplay_5.png`;
    rulesText6 = `./assets/${window.lang}/paytable/texts/rules_howtoplay_6.png`;
    paytableText3 = `./assets/${window.lang}/paytable/texts/game_rules_rule3.png`;
    paytableText1 = `./assets/${window.lang}/paytable/texts/game_rules_rule1.png`;
    paytableText2 = `./assets/${window.lang}/paytable/texts/game_rules_rule2.png`;
    paytableText4 = `./assets/${window.lang}/paytable/texts/game_rules_rule4.png`;
    paytableWild = `./assets/${window.lang}/paytable/aztec_paytable_wild.png`;
    paytableText7 = `./assets/${window.lang}/paytable/texts/game_rules_rule7.png`;
    paytableText9 = `./assets/${window.lang}/paytable/texts/game_rules_rule9.png`;
    paytableText8 = window.rtp !== 95 ? `./assets/${window.lang}/paytable/texts/game_rules_rule8_${window.rtp}.png` : `./assets/${window.lang}/paytable/texts/game_rules_rule8.png`;
    paytableTextHeader2 = `./assets/${window.lang}/paytable/texts/game_rules_features.png`;
    paytableFSBadge = `./assets/${window.lang}/paytable/aztec_paytable_spinsofplenty_badge.png`;
    paytableText13 = `./assets/${window.lang}/paytable/texts/game_rules_rule13.png`;
    paytableText14 = `./assets/${window.lang}/paytable/texts/game_rules_rule14.png`;
    paytableText15 = `./assets/${window.lang}/paytable/texts/game_rules_rule15.png`;
    paytableText16 = `./assets/${window.lang}/paytable/texts/game_rules_rule16.png`;
    paytableScreen1 = `./assets/${window.lang}/paytable/aztec_paytable_screen01.png`;
    paytableText17 = `./assets/${window.lang}/paytable/texts/game_rules_rule17.png`;
    paytableScreen2 = `./assets/${window.lang}/paytable/aztec_paytable_screen02.png`;
    paytablePlayFSPopup = `./assets/${window.lang}/fs_popup/texts/fs_popup_play_normal.png`;
    paytableTextFSPopup = `./assets/${window.lang}/fs_popup/texts/fs_popup_expand.png`;
    paytableText18 = `./assets/${window.lang}/paytable/texts/game_rules_rule18.png`;
    paytableFS = `./assets/${window.lang}/paytable/aztec_paytable_fs_icon.png`;
    paytableText19 = `./assets/${window.lang}/paytable/texts/game_rules_rule19.png`;
    paytableText20 = `./assets/${window.lang}/paytable/texts/game_rules_rule20.png`;
    paytableTextHeader3 = `./assets/${window.lang}/paytable/texts/game_rules_symbols.png`;
    paytableTextHeader4 = `./assets/${window.lang}/paytable/texts/game_rules_paylines.png`;
    paytablePaylines = `./assets/${window.lang}/paytable/aztec_paytable_paylines.png`;
    incompleteGames = `./assets/${window.lang}/paytable/texts/game_rules_rule10.png`;

    hoverOver () {
        (this.$refs.closeButton as HTMLElement).style.backgroundImage = `url(${this.closeButtonHover})`;
    }
    hoverOut () {
        (this.$refs.closeButton as HTMLElement).style.backgroundImage = `url(${this.closeButton})`;
    }
    handleCloseButtonClick () {
        this.$store.dispatch('rules/hideRules');
        this.playSound('UI_Click_2');
        this.isJurisdictionForceActive && EventBus.$emit('RESUME_RAF', { type: 'RESUME_RAF' });
        this.isJurisdictionForceActive && EventBus.$emit('STOP_SOUNDS', { type: 'STOP_SOUNDS', value: { action: 'PAUSE', value: false } });
    }
    get autoplayDisable (): boolean {
        return this.$store.state.autoplay.autoplayDisable;
    }
    get isJurisdictionForceActive (): boolean {
        return (window.jurisdiction === 'SE' || window.jurisdiction === 'DK');
    }
}
